exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-sanity-post-slug-current-tsx": () => import("./../../../src/pages/blog/{sanityPost.slug__current}.tsx" /* webpackChunkName: "component---src-pages-blog-sanity-post-slug-current-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-en-proceso-tsx": () => import("./../../../src/pages/en-proceso.tsx" /* webpackChunkName: "component---src-pages-en-proceso-tsx" */),
  "component---src-pages-home-staging-tsx": () => import("./../../../src/pages/home-staging.tsx" /* webpackChunkName: "component---src-pages-home-staging-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-aviso-legal-mdx": () => import("./../../../src/pages/legal/aviso-legal.mdx" /* webpackChunkName: "component---src-pages-legal-aviso-legal-mdx" */),
  "component---src-pages-legal-politica-de-cookies-mdx": () => import("./../../../src/pages/legal/politica-de-cookies.mdx" /* webpackChunkName: "component---src-pages-legal-politica-de-cookies-mdx" */),
  "component---src-pages-legal-politica-de-privacidad-mdx": () => import("./../../../src/pages/legal/politica-de-privacidad.mdx" /* webpackChunkName: "component---src-pages-legal-politica-de-privacidad-mdx" */),
  "component---src-pages-quienes-somos-tsx": () => import("./../../../src/pages/quienes-somos.tsx" /* webpackChunkName: "component---src-pages-quienes-somos-tsx" */),
  "component---src-properties-templates-properties-page-properties-tsx": () => import("./../../../src/properties/templates/properties/PageProperties.tsx" /* webpackChunkName: "component---src-properties-templates-properties-page-properties-tsx" */),
  "component---src-properties-templates-property-page-property-tsx": () => import("./../../../src/properties/templates/property/PageProperty.tsx" /* webpackChunkName: "component---src-properties-templates-property-page-property-tsx" */)
}

